const Login = () => import (/* webpackChunkName: "common-group" */ './views/account/Login.vue');
const Dashboard = () => import (/* webpackChunkName: "common-group" */'./views/Dashboard/Dashboard.vue');
const Profile = () => import (/* webpackChunkName: "common-group" */'./views/Profile/Profile.vue');
const Security = () => import (/* webpackChunkName: "common-group" */'./views/Profile/Security.vue');

const UserIndex = () => import (/* webpackChunkName: "user-group" */'./views/Users/UserIndex.vue');
const UserForm = () => import (/* webpackChunkName: "user-group" */'./views/Users/UserForm.vue');
const RoleIndex = () => import (/* webpackChunkName: "user-group" */'./views/Role/RoleIndex.vue');
const RoleForm = () => import (/* webpackChunkName: "user-group" */'./views/Role/RoleForm.vue');
// const UserView = () => import (/* webpackChunkName: "program-group" */'./views/Program/ProgramView.vue');

const ProgramProgressIndex = () => import (/* webpackChunkName: "program-progress-industry" */ './views/ProgramProgress/ProgramProgressIndex');
const ProgramProgressForm = () => import (/* webpackChunkName: "program-progress-industry" */ './views/ProgramProgress/ProgramProgressForm.vue');
const ProgramProgressView = () => import (/* webpackChunkName: "program-progress-industry" */ './views/ProgramProgress/ProgramProgressView.vue');
const ProgramProgressArrange = () => import (/* webpackChunkName: "program-progress-industry" */ './views/ProgramProgress/ProgramProgressArrange.vue');
const MonthlyProgressIndex = () => import (/* webpackChunkName: "program-progress-industry" */ './views/MonthlyProgress/MonthlyProgressIndex');
const MonthlyProgressForm = () => import (/* webpackChunkName: "program-progress-industry" */ './views/MonthlyProgress/MonthlyProgressForm.vue');
const MonthlyProgressView = () => import (/* webpackChunkName: "program-progress-industry" */ './views/MonthlyProgress/MonthlyProgressView.vue');
const MonthlyProgressReport = () => import (/* webpackChunkName: "program-progress-industry" */ './views/MonthlyProgress/MonthlyProgressReport.vue');

const ForestIndex = () => import (/* webpackChunkName: "forest-group" */ './views/Forest/ForestIndex');
const ForestForm = () => import (/* webpackChunkName: "forest-group" */ './views/Forest/ForestForm.vue');
const ForestView = () => import (/* webpackChunkName: "forest-group" */ './views/Forest/ForestView.vue');
const ForestReport = () => import (/* webpackChunkName: "forest-group" */ './views/Forest/ForestReport.vue');
const ForestSurveyIndex = () => import (/* webpackChunkName: "forest-group" */ './views/ForestSurvey/ForestSurveyIndex');
const ForestSurveyForm = () => import (/* webpackChunkName: "forest-group" */ './views/ForestSurvey/ForestSurveyForm.vue');
const ForestSurveyView = () => import (/* webpackChunkName: "forest-group" */ './views/ForestSurvey/ForestSurveyView.vue');

//Division Forest
const PlantPDIndex = () => import (/* webpackChunkName: "forest-group" */ './views/PlantPd/PlantPDIndex');
const PlantPDForm = () => import (/* webpackChunkName: "forest-group" */ './views/PlantPd/PlantPDForm.vue');
const PlantPDView = () => import (/* webpackChunkName: "forest-group" */ './views/PlantPd/PlantPDView.vue');
const PlantPDReport = () => import (/* webpackChunkName: "forest-group" */ './views/PlantPd/PlantPDReport.vue');
const WoodSupplyIndex = () => import (/* webpackChunkName: "forest-group" */ './views/WoodSupply/WoodSupplyIndex');
const WoodSupplyForm = () => import (/* webpackChunkName: "forest-group" */ './views/WoodSupply/WoodSupplyForm.vue');
const WoodSupplyView = () => import (/* webpackChunkName: "forest-group" */ './views/WoodSupply/WoodSupplyView.vue');
const WoodSupplyReport = () => import (/* webpackChunkName: "forest-group" */ './views/WoodSupply/WoodSupplyReport.vue');
const KhotoIndex = () => import (/* webpackChunkName: "forest-group" */ './views/Khoto/KhotoIndex');
const KhotoForm = () => import (/* webpackChunkName: "forest-group" */ './views/Khoto/KhotoForm.vue');
const KhotoView = () => import (/* webpackChunkName: "forest-group" */ './views/Khoto/KhotoView.vue');
const KhotoReport = () => import (/* webpackChunkName: "forest-group" */ './views/Khoto/KhotoReport.vue');
const KathDauraIndex = () => import (/* webpackChunkName: "forest-group" */ './views/KathDaura/KathDauraIndex');
const KathDauraForm = () => import (/* webpackChunkName: "forest-group" */ './views/KathDaura/KathDauraForm.vue');
const KathDauraView = () => import (/* webpackChunkName: "forest-group" */ './views/KathDaura/KathDauraView.vue');
const KathDauraReport = () => import (/* webpackChunkName: "forest-group" */ './views/KathDaura/KathDauraReport.vue');
const KathDauraTemplate = () => import (/* webpackChunkName: "forest-group" */ './views/KathDaura/KathDauraTemplate.vue');
const CfugIndex = () => import (/* webpackChunkName: "forest-group" */ './views/Cfug/CfugIndex');
const CfugForm = () => import (/* webpackChunkName: "forest-group" */ './views/Cfug/CfugForm.vue');
const CfugView = () => import (/* webpackChunkName: "forest-group" */ './views/Cfug/CfugView.vue');
const CfugReport = () => import (/* webpackChunkName: "forest-group" */ './views/Cfug/CfugReport.vue');
const JadibutiIndex = () => import (/* webpackChunkName: "forest-group" */ './views/Jadibuti/JadibutiIndex');
const JadibutiForm = () => import (/* webpackChunkName: "forest-group" */ './views/Jadibuti/JadibutiForm.vue');
const JadibutiView = () => import (/* webpackChunkName: "forest-group" */ './views/Jadibuti/JadibutiView.vue');
const JadibutiReport = () => import (/* webpackChunkName: "forest-group" */ './views/Jadibuti/JadibutiReport.vue');

const JadibutiListIndex = () => import (/* webpackChunkName: "forest-group" */ './views/JadibutiList/JadibutiListIndex');
const JadibutiListForm = () => import (/* webpackChunkName: "forest-group" */ './views/JadibutiList/JadibutiListForm.vue');

const CfDetailIndex = () => import (/* webpackChunkName: "forest-group" */ './views/CfDetail/CfDetailIndex');
const CfDetailForm = () => import (/* webpackChunkName: "forest-group" */ './views/CfDetail/CfDetailForm.vue');
const CfDetailView = () => import (/* webpackChunkName: "forest-group" */ './views/CfDetail/CfDetailView.vue');
const CfDetailReport = () => import (/* webpackChunkName: "forest-group" */ './views/CfDetail/CfDetailReport.vue');
const LhfDetailIndex = () => import (/* webpackChunkName: "forest-group" */ './views/LhfDetail/LhfDetailIndex');
const LhfDetailForm = () => import (/* webpackChunkName: "forest-group" */ './views/LhfDetail/LhfDetailForm.vue');
const LhfDetailView = () => import (/* webpackChunkName: "forest-group" */ './views/LhfDetail/LhfDetailView.vue');
const LhfDetailReport = () => import (/* webpackChunkName: "forest-group" */ './views/LhfDetail/LhfDetailReport.vue');

const LeaveIndex = () => import (/* webpackChunkName: "leave-group" */ './views/Leave/LeaveIndex');
const LeaveForm = () => import (/* webpackChunkName: "leave-group" */ './views/Leave/LeaveForm.vue');
const LeaveView = () => import (/* webpackChunkName: "leave-group" */ './views/Leave/LeaveView.vue');
const LeaveReport = () => import (/* webpackChunkName: "leave-group" */ './views/Leave/LeaveView.vue');
const FeedbackIndex = () => import (/* webpackChunkName: "feedback-group" */'./views/Feedback/FeedbackIndex.vue');
const FeedbackForm = () => import (/* webpackChunkName: "feedback-group" */'./views/Feedback/FeedbackForm.vue');
const FeedbackView = () => import (/* webpackChunkName: "feedback-group" */'./views/Feedback/FeedbackView.vue');

const EmployeeDetailIndex = () => import (/* webpackChunkName: "employee-group" */ './views/EmployeeDetail/EmployeeDetailIndex.vue');
const EmployeeDetailForm = () => import (/* webpackChunkName: "employee-group" */ './views/EmployeeDetail/EmployeeDetailForm.vue');
const EmployeeDetailView = () => import (/* webpackChunkName: "employee-group" */ './views/EmployeeDetail/EmployeeDetailView.vue');
const EmployeeDetailReport = () => import (/* webpackChunkName: "employee-group" */ './views/EmployeeDetail/EmployeeDetailReport.vue');
const CaseDetailIndex = () => import (/* webpackChunkName: "case-group" */ './views/CaseDetail/CaseDetailIndex');
const CaseDetailForm = () => import (/* webpackChunkName: "case-group" */ './views/CaseDetail/CaseDetailForm.vue');
const CaseDetailView = () => import (/* webpackChunkName: "case-group" */ './views/CaseDetail/CaseDetailView.vue');
const CaseDetailReport = () => import (/* webpackChunkName: "case-group" */ './views/CaseDetail/CaseDetailReport.vue');
const FifteenDetailIndex = () => import (/* webpackChunkName: "Fifteen-group" */ './views/FifteenDetail/FifteenDetailIndex');
const FifteenDetailForm = () => import (/* webpackChunkName: "Fifteen-group" */ './views/FifteenDetail/FifteenDetailForm.vue');
const FifteenDetailView = () => import (/* webpackChunkName: "Fifteen-group" */ './views/FifteenDetail/FifteenDetailView.vue');
const FifteenDetailReport = () => import (/* webpackChunkName: "Fifteen-group" */ './views/FifteenDetail/FifteenDetailReport.vue');

const TippaniIndex = () => import (/* webpackChunkName: "group-post" */ './views/Tippani/TippaniIndex.vue');
const TippaniForm = () => import (/* webpackChunkName: "group-post" */ './views/Tippani/TippaniForm.vue');
const TippaniView = () => import (/* webpackChunkName: "group-post" */ './views/Tippani/TippaniView.vue');
const TippaniApproved = () => import (/* webpackChunkName: "group-post" */ './views/Tippani/TippaniApproved.vue');
const PrintPostView = () => import (/* webpackChunkName: "group-post" */ './views/Tippani/PrintView.vue');

const TemplateIndex = () => import (/* webpackChunkName: "group-template" */ './views/Template/TemplateIndex.vue');
const TemplateForm = () => import (/* webpackChunkName: "group-template" */ './views/Template/TemplateForm.vue');
const TemplateView = () => import (/* webpackChunkName: "group-template" */ './views/Template/TemplateView.vue');
const CommonTemplateView = () => import (/* webpackChunkName: "group-template" */ './views/Components/CommonTemplateView.vue');

//Niji
const NijiAabadiIndex = () => import (/* webpackChunkName: "forest-group" */ './views/NijiAabadi/NijiAabadiIndex');
const NijiAabadiForm = () => import (/* webpackChunkName: "forest-group" */ './views/NijiAabadi/NijiAabadiForm.vue');
const NijiAabadiView = () => import (/* webpackChunkName: "forest-group" */ './views/NijiAabadi/NijiAabadiView.vue');
const NijiAabadiReport = () => import (/* webpackChunkName: "forest-group" */ './views/NijiAabadi/NijiAabadiReport.vue');
const NijiJadibutiIndex = () => import (/* webpackChunkName: "forest-group" */ './views/Niji/NijiJadibuti/NijiJadibutiIndex');
const NijiJadibutiForm = () => import (/* webpackChunkName: "forest-group" */ './views/Niji/NijiJadibuti/NijiJadibutiForm.vue');
const NijiJadibutiView = () => import (/* webpackChunkName: "forest-group" */ './views/Niji/NijiJadibuti/NijiJadibutiView.vue');
const NijiJadibutiReport = () => import (/* webpackChunkName: "forest-group" */ './views/Niji/NijiJadibuti/NijiJadibutiReport.vue');

//Niji Kath Daura Nikasi
const NijiKathDauraNikasiIndex = () => import (/* webpackChunkName: "nikasi-group" */ './views/Niji/NijiKathDauraNikasi/NijiKathDauraNikasiIndex');
const NijiKathDauraNikasiForm = () => import (/* webpackChunkName: "nikasi-group" */ './views/Niji/NijiKathDauraNikasi/NijiKathDauraNikasiForm.vue');
const NijiKathDauraNikasiView = () => import (/* webpackChunkName: "nikasi-group" */ './views/Niji/NijiKathDauraNikasi/NijiKathDauraNikasiView.vue');
const NijiKathDauraNikasiReport = () => import (/* webpackChunkName: "nikasi-group" */ './views/Niji/NijiKathDauraNikasi/NijiKathDauraNikasiReport.vue');

//Niji Kath Daura Katani
const NijiKathDauraKataniIndex = () => import (/* webpackChunkName: "katan-group" */ './views/Niji/NijiKathDauraKatani/NijiKathDauraKataniIndex');
const NijiKathDauraKataniForm = () => import (/* webpackChunkName: "katan-group" */ './views/Niji/NijiKathDauraKatani/NijiKathDauraKataniForm.vue');
const NijiKathDauraKataniView = () => import (/* webpackChunkName: "katan-group" */ './views/Niji/NijiKathDauraKatani/NijiKathDauraKataniView.vue');
const NijiKathDauraKataniReport = () => import (/* webpackChunkName: "katan-group" */ './views/Niji/NijiKathDauraKatani/NijiKathDauraKataniReport.vue');

//Niji Jadibuti Nikash
const NijiJadibutiNikasiIndex = () => import (/* webpackChunkName: "nikasi-group" */ './views/Niji/NijiJadibutiNikasi/NijiJadibutiNikasiIndex');
const NijiJadibutiNikasiForm = () => import (/* webpackChunkName: "nikasi-group" */ './views/Niji/NijiJadibutiNikasi/NijiJadibutiNikasiForm.vue');
const NijiJadibutiNikasiView = () => import (/* webpackChunkName: "nikasi-group" */ './views/Niji/NijiJadibutiNikasi/NijiJadibutiNikasiView.vue');
const NijiJadibutiNikasiReport = () => import (/* webpackChunkName: "nikasi-group" */ './views/Niji/NijiJadibutiNikasi/NijiJadibutiNikasiReport.vue');

//Niji Jadibuti Nikash
const NijiJadibutiSankalanIndex = () => import (/* webpackChunkName: "sankalan-group" */ './views/Niji/NijiJadibutiSankalan/NijiJadibutiSankalanIndex');
const NijiJadibutiSankalanForm = () => import (/* webpackChunkName: "sankalan-group" */ './views/Niji/NijiJadibutiSankalan/NijiJadibutiSankalanForm.vue');
const NijiJadibutiSankalanView = () => import (/* webpackChunkName: "sankalan-group" */ './views/Niji/NijiJadibutiSankalan/NijiJadibutiSankalanView.vue');
const NijiJadibutiSankalanReport = () => import (/* webpackChunkName: "sankalan-group" */ './views/Niji/NijiJadibutiSankalan/NijiJadibutiSankalanReport.vue');

//Samudayik
//Jadibuti Nikash
const SamudayikJadibutiNikasiIndex = () => import (/* webpackChunkName: "nikasi-group" */ './views/Samudayik/JadibutiNikasi/JadibutiNikasiIndex');
const SamudayikJadibutiNikasiForm = () => import (/* webpackChunkName: "nikasi-group" */ './views/Samudayik/JadibutiNikasi/JadibutiNikasiForm.vue');
const SamudayikJadibutiNikasiView = () => import (/* webpackChunkName: "nikasi-group" */ './views/Samudayik/JadibutiNikasi/JadibutiNikasiView.vue');
const SamudayikJadibutiNikasiReport = () => import (/* webpackChunkName: "nikasi-group" */ './views/Samudayik/JadibutiNikasi/JadibutiNikasiReport.vue');
//Jadibuti Nikash
const SamudayikJadibutiSankalanIndex = () => import (/* webpackChunkName: "sankalan-group" */ './views/Samudayik/JadibutiSankalan/JadibutiSankalanIndex');
const SamudayikJadibutiSankalanForm = () => import (/* webpackChunkName: "sankalan-group" */ './views/Samudayik/JadibutiSankalan/JadibutiSankalanForm.vue');
const SamudayikJadibutiSankalanView = () => import (/* webpackChunkName: "sankalan-group" */ './views/Samudayik/JadibutiSankalan/JadibutiSankalanView.vue');
const SamudayikJadibutiSankalanReport = () => import (/* webpackChunkName: "sankalan-group" */ './views/Samudayik/JadibutiSankalan/JadibutiSankalanReport.vue');
//KathDaura Nikash
const SamudayikKathDauraNikasiIndex = () => import (/* webpackChunkName: "nikasi-group" */ './views/Samudayik/KathDauraNikasi/KathDauraNikasiIndex');
const SamudayikKathDauraNikasiForm = () => import (/* webpackChunkName: "nikasi-group" */ './views/Samudayik/KathDauraNikasi/KathDauraNikasiForm.vue');
const SamudayikKathDauraNikasiView = () => import (/* webpackChunkName: "nikasi-group" */ './views/Samudayik/KathDauraNikasi/KathDauraNikasiView.vue');
const SamudayikKathDauraNikasiReport = () => import (/* webpackChunkName: "nikasi-group" */ './views/Samudayik/KathDauraNikasi/KathDauraNikasiReport.vue');
//KathDaura Nikash
const SamudayikKathDauraSankalanIndex = () => import (/* webpackChunkName: "sankalan-group" */ './views/Samudayik/KathDauraSankalan/KathDauraSankalanIndex');
const SamudayikKathDauraSankalanForm = () => import (/* webpackChunkName: "sankalan-group" */ './views/Samudayik/KathDauraSankalan/KathDauraSankalanForm.vue');
const SamudayikKathDauraSankalanView = () => import (/* webpackChunkName: "sankalan-group" */ './views/Samudayik/KathDauraSankalan/KathDauraSankalanView.vue');
const SamudayikKathDauraSankalanReport = () => import (/* webpackChunkName: "sankalan-group" */ './views/Samudayik/KathDauraSankalan/KathDauraSankalanReport.vue');
//Khoto Nikash
const SamudayikKhotoNikasiIndex = () => import (/* webpackChunkName: "nikasi-group" */ './views/Samudayik/KhotoNikasi/KhotoNikasiIndex');
const SamudayikKhotoNikasiForm = () => import (/* webpackChunkName: "nikasi-group" */ './views/Samudayik/KhotoNikasi/KhotoNikasiForm.vue');
const SamudayikKhotoNikasiView = () => import (/* webpackChunkName: "nikasi-group" */ './views/Samudayik/KhotoNikasi/KhotoNikasiView.vue');
const SamudayikKhotoNikasiReport = () => import (/* webpackChunkName: "nikasi-group" */ './views/Samudayik/KhotoNikasi/KhotoNikasiReport.vue');
//Khoto Nikash
const SamudayikKhotoSankalanIndex = () => import (/* webpackChunkName: "sankalan-group" */ './views/Samudayik/KhotoSankalan/KhotoSankalanIndex');
const SamudayikKhotoSankalanForm = () => import (/* webpackChunkName: "sankalan-group" */ './views/Samudayik/KhotoSankalan/KhotoSankalanForm.vue');
const SamudayikKhotoSankalanView = () => import (/* webpackChunkName: "sankalan-group" */ './views/Samudayik/KhotoSankalan/KhotoSankalanView.vue');
const SamudayikKhotoSankalanReport = () => import (/* webpackChunkName: "sankalan-group" */ './views/Samudayik/KhotoSankalan/KhotoSankalanReport.vue');

//Aabadi
//Aabadi Chhodpurji
const AabadiChhodpurjiIndex = () => import (/* webpackChunkName: "chhodpurji-group" */ './views/Aabadi/AabadiChhodpurji/AabadiChhodpurjiIndex');
const AabadiChhodpurjiForm = () => import (/* webpackChunkName: "chhodpurji-group" */ './views/Aabadi/AabadiChhodpurji/AabadiChhodpurjiForm.vue');
const AabadiChhodpurjiView = () => import (/* webpackChunkName: "chhodpurji-group" */ './views/Aabadi/AabadiChhodpurji/AabadiChhodpurjiView.vue');
const AabadiChhodpurjiReport = () => import (/* webpackChunkName: "chhodpurji-group" */ './views/Aabadi/AabadiChhodpurji/AabadiChhodpurjiReport.vue');
//Aabadi Katan
const AabadiKatanIndex = () => import (/* webpackChunkName: "katan-group" */ './views/Aabadi/AabadiKatan/AabadiKatanIndex');
const AabadiKatanForm = () => import (/* webpackChunkName: "katan-group" */ './views/Aabadi/AabadiKatan/AabadiKatanForm.vue');
const AabadiKatanView = () => import (/* webpackChunkName: "katan-group" */ './views/Aabadi/AabadiKatan/AabadiKatanView.vue');
const AabadiKatanReport = () => import (/* webpackChunkName: "katan-group" */ './views/Aabadi/AabadiKatan/AabadiKatanReport.vue');

//Sarkari
//Jadibuti Chhodpurji
const SarkariJadibutiChhodpurjiIndex = () => import (/* webpackChunkName: "chhodpurji-group" */ './views/Sarkari/JadibutiChhodpurji/JadibutiChhodpurjiIndex');
const SarkariJadibutiChhodpurjiForm = () => import (/* webpackChunkName: "chhodpurji-group" */ './views/Sarkari/JadibutiChhodpurji/JadibutiChhodpurjiForm.vue');
const SarkariJadibutiChhodpurjiView = () => import (/* webpackChunkName: "chhodpurji-group" */ './views/Sarkari/JadibutiChhodpurji/JadibutiChhodpurjiView.vue');
const SarkariJadibutiChhodpurjiReport = () => import (/* webpackChunkName: "chhodpurji-group" */ './views/Sarkari/JadibutiChhodpurji/JadibutiChhodpurjiReport.vue');
//Jadibuti Sankalan
const SarkariJadibutiSankalanIndex = () => import (/* webpackChunkName: "sankalan-group" */ './views/Sarkari/JadibutiSankalan/JadibutiSankalanIndex');
const SarkariJadibutiSankalanForm = () => import (/* webpackChunkName: "sankalan-group" */ './views/Sarkari/JadibutiSankalan/JadibutiSankalanForm.vue');
const SarkariJadibutiSankalanView = () => import (/* webpackChunkName: "sankalan-group" */ './views/Sarkari/JadibutiSankalan/JadibutiSankalanView.vue');
const SarkariJadibutiSankalanReport = () => import (/* webpackChunkName: "sankalan-group" */ './views/Sarkari/JadibutiSankalan/JadibutiSankalanReport.vue');
//KathDaura Chhodpurji
const SarkariKathDauraChhodpurjiIndex = () => import (/* webpackChunkName: "chhodpurji-group" */ './views/Sarkari/KathDauraChhodpurji/KathDauraChhodpurjiIndex');
const SarkariKathDauraChhodpurjiForm = () => import (/* webpackChunkName: "chhodpurji-group" */ './views/Sarkari/KathDauraChhodpurji/KathDauraChhodpurjiForm.vue');
const SarkariKathDauraChhodpurjiView = () => import (/* webpackChunkName: "chhodpurji-group" */ './views/Sarkari/KathDauraChhodpurji/KathDauraChhodpurjiView.vue');
const SarkariKathDauraChhodpurjiReport = () => import (/* webpackChunkName: "chhodpurji-group" */ './views/Sarkari/KathDauraChhodpurji/KathDauraChhodpurjiReport.vue');
//KathDaura Katan
const SarkariKathDauraKatanIndex = () => import (/* webpackChunkName: "katan-group" */ './views/Sarkari/KathDauraKatan/KathDauraKatanIndex');
const SarkariKathDauraKatanForm = () => import (/* webpackChunkName: "katan-group" */ './views/Sarkari/KathDauraKatan/KathDauraKatanForm.vue');
const SarkariKathDauraKatanView = () => import (/* webpackChunkName: "katan-group" */ './views/Sarkari/KathDauraKatan/KathDauraKatanView.vue');
const SarkariKathDauraKatanReport = () => import (/* webpackChunkName: "katan-group" */ './views/Sarkari/KathDauraKatan/KathDauraKatanReport.vue');

//Shamati Tippani
//Lilam Sahamati
const LilamSahamatiIndex = () => import (/* webpackChunkName: "sahamati-tippani-group" */ './views/SahamatiTippani/LilamSahamati/LilamSahamatiIndex');
const LilamSahamatiForm = () => import (/* webpackChunkName: "sahamati-tippani-group" */ './views/SahamatiTippani/LilamSahamati/LilamSahamatiForm.vue');
const LilamSahamatiView = () => import (/* webpackChunkName: "sahamati-tippani-group" */ './views/SahamatiTippani/LilamSahamati/LilamSahamatiView.vue');
const LilamSahamatiReport = () => import (/* webpackChunkName: "sahamati-tippani-group" */ './views/SahamatiTippani/LilamSahamati/LilamSahamatiReport.vue');
//Sthanataran Sahamati
const SthanantaranSahamatiIndex = () => import (/* webpackChunkName: "sahamati-tippani-group" */ './views/SahamatiTippani/SthanantaranSahamati/SthanantaranSahamatiIndex');
const SthanantaranSahamatiForm = () => import (/* webpackChunkName: "sahamati-tippani-group" */ './views/SahamatiTippani/SthanantaranSahamati/SthanantaranSahamatiForm.vue');
const SthanantaranSahamatiView = () => import (/* webpackChunkName: "sahamati-tippani-group" */ './views/SahamatiTippani/SthanantaranSahamati/SthanantaranSahamatiView.vue');
const SthanantaranSahamatiReport = () => import (/* webpackChunkName: "sahamati-tippani-group" */ './views/SahamatiTippani/SthanantaranSahamati/SthanantaranSahamatiReport.vue');
//Lilam Bikri
const LilamBikriIndex = () => import (/* webpackChunkName: "sahamati-tippani-group" */ './views/SahamatiTippani/LilamBikri/LilamBikriIndex');
const LilamBikriForm = () => import (/* webpackChunkName: "sahamati-tippani-group" */ './views/SahamatiTippani/LilamBikri/LilamBikriForm.vue');
const LilamBikriView = () => import (/* webpackChunkName: "sahamati-tippani-group" */ './views/SahamatiTippani/LilamBikri/LilamBikriView.vue');
const LilamBikriReport = () => import (/* webpackChunkName: "sahamati-tippani-group" */ './views/SahamatiTippani/LilamBikri/LilamBikriReport.vue');

const routes = [
    {
        path: '/',
        name: 'login',
        component: Login,
        meta: {
            requiresVisitor: true,
        }
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            title: 'Dashboard', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/profile',
        name: 'profile',
        component: Profile,
        meta: {
            title: 'Profile', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/security',
        name: 'security',
        component: Security,
        meta: {
            title: 'Security', rule: true,
            requiresAuth: true,
        }
    },

    //Users Management
    {
        path: '/users',
        name: 'user-index',
        params: true,
        component: UserIndex,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/users/create',
        name: 'user-create',
        params: true,
        component: UserForm,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/users/:username/edit',
        name: 'user-edit',
        params: true,
        component: UserForm,
        meta: {
            requiresAuth: true,
        }
    },

    //Role Management
    {
        path: '/roles',
        name: 'role-index',
        params: true,
        component: RoleIndex,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/roles/create',
        name: 'role-create',
        params: true,
        component: RoleForm,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/roles/:id/edit',
        name: 'role-edit',
        params: true,
        component: RoleForm,
        meta: {
            requiresAuth: true,
        }
    },

    //ForestEntry Management
    {
        path: '/forests',
        name: 'forest-index',
        component: ForestIndex,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/forests/create',
        name: 'forest-create',
        component: ForestForm,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/forests/:uid/edit',
        name: 'forest-edit',
        params: true,
        component: ForestForm,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/forests/:uid/view',
        name: 'forest-view',
        component: ForestView,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/forests/report',
        name: 'forest-report',
        component: ForestReport,
        meta: {
            requiresAuth: true,
        }
    },

    //Monthly Program Progress
    {
        path: '/monthly-progress',
        name: 'monthly-progress-index',
        component: MonthlyProgressIndex,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/monthly-progress/create',
        name: 'monthly-progress-create',
        component: MonthlyProgressForm,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/monthly-progress/:uid/edit',
        name: 'monthly-progress-edit',
        params: true,
        component: MonthlyProgressForm,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/monthly-progress/:uid/view',
        name: 'monthly-progress-view',
        component: MonthlyProgressView,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/monthly-progress/report',
        name: 'monthly-progress-report',
        component: MonthlyProgressReport,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/program-progress',
        name: 'program-progress-index',
        component: ProgramProgressIndex,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/program-progress/create',
        name: 'program-progress-create',
        component: ProgramProgressForm,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/program-progress/:id/edit',
        name: 'program-progress-edit',
        params: true,
        component: ProgramProgressForm,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/program-progress/:id/view',
        name: 'program-progress-view',
        component: ProgramProgressView,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/program-progress/arrange',
        name: 'program-progress-arrange',
        component: ProgramProgressArrange,
        meta: {
            title: 'Arrange Program', rule: true,
            requiresAuth: true,
        }
    },

    //ForestEntry Management
    {
        path: '/forest-survey',
        name: 'forest-survey-index',
        component: ForestSurveyIndex,
        meta: {
            title: 'Forest Survey',
            requiresAuth: true,
        }
    },
    {
        path: '/forest-survey/create',
        name: 'forest-survey-create',
        component: ForestSurveyForm,
        meta: {
            title: 'Create Forest Survey',
            requiresAuth: true,
        }
    },
    {
        path: '/forest-survey/:id/edit',
        name: 'forest-survey-edit',
        params: true,
        component: ForestSurveyForm,
        meta: {
            title: 'Edit Forest Survey',
            rule: true, requiresAuth: true,
        }
    },
    {
        path: '/forest-survey/:id/view',
        name: 'forest-survey-view',
        component: ForestSurveyView,
        meta: {
            title: 'View Forest Survey', rule: true,
            requiresAuth: true,
        }
    },

    //Plant Production Distribution
    {
        path: '/plant-pd',
        name: 'plant-pd-index',
        component: PlantPDIndex,
        meta: {
            title: 'Plant Production Distribution',
            requiresAuth: true,
        }
    },
    {
        path: '/plant-pd/create',
        name: 'plant-pd-create',
        component: PlantPDForm,
        meta: {
            title: 'Create Plant Production Distribution',
            requiresAuth: true,
        }
    },
    {
        path: '/plant-pd/:uid/edit',
        name: 'plant-pd-edit',
        params: true,
        component: PlantPDForm,
        meta: {
            title: 'Edit Plant Production Distribution',
            rule: true, requiresAuth: true,
        }
    },
    {
        path: '/plant-pd/:uid/view',
        name: 'plant-pd-view',
        component: PlantPDView,
        meta: {
            title: 'View Plant Production Distribution', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/plant-pd/report',
        name: 'plant-pd-report',
        component: PlantPDReport,
        meta: {
            title: 'Report Plant Production Distribution', rule: true,
            requiresAuth: true,
        }
    },

    //Wood Supply
    {
        path: '/wood-supply',
        name: 'wood-supply-index',
        component: WoodSupplyIndex,
        meta: {
            title: 'Wood Supply',
            requiresAuth: true,
        }
    },
    {
        path: '/wood-supply/create',
        name: 'wood-supply-create',
        component: WoodSupplyForm,
        meta: {
            title: 'Create Wood Supply',
            requiresAuth: true,
        }
    },
    {
        path: '/wood-supply/:uid/edit',
        name: 'wood-supply-edit',
        params: true,
        component: WoodSupplyForm,
        meta: {
            title: 'Edit Wood Supply',
            rule: true, requiresAuth: true,
        }
    },
    {
        path: '/wood-supply/:uid/view',
        name: 'wood-supply-view',
        component: WoodSupplyView,
        meta: {
            title: 'View Wood Supply', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/wood-supply/report',
        name: 'wood-supply-report',
        component: WoodSupplyReport,
        meta: {
            title: 'Report Wood Supply', rule: true,
            requiresAuth: true,
        }
    },

    //Khoto
    {
        path: '/khoto',
        name: 'khoto-index',
        component: KhotoIndex,
        meta: {
            title: 'Khoto Management',
            requiresAuth: true,
        }
    },
    {
        path: '/khoto/create',
        name: 'khoto-create',
        component: KhotoForm,
        meta: {
            title: 'Create Khoto Management',
            requiresAuth: true,
        }
    },
    {
        path: '/khoto/:uid/edit',
        name: 'khoto-edit',
        params: true,
        component: KhotoForm,
        meta: {
            title: 'Edit Khoto Management',
            rule: true, requiresAuth: true,
        }
    },
    {
        path: '/khoto/:uid/view',
        name: 'khoto-view',
        component: KhotoView,
        meta: {
            title: 'View Khoto Management', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/khoto/report',
        name: 'khoto-report',
        component: KhotoReport,
        meta: {
            title: 'Report Khoto Management', rule: true,
            requiresAuth: true,
        }
    },

    //Kath Daura
    {
        path: '/kath-daura',
        name: 'kath-daura-index',
        component: KathDauraIndex,
        meta: {
            title: 'Kath Daura Management',
            requiresAuth: true,
        }
    },
    {
        path: '/kath-daura/create',
        name: 'kath-daura-create',
        component: KathDauraForm,
        meta: {
            title: 'Create Kath Daura Management',
            requiresAuth: true,
        }
    },
    {
        path: '/kath-daura/:uid/edit',
        name: 'kath-daura-edit',
        params: true,
        component: KathDauraForm,
        meta: {
            title: 'Edit Kath Daura Management',
            rule: true, requiresAuth: true,
        }
    },
    {
        path: '/kath-daura/:uid/view',
        name: 'kath-daura-view',
        component: KathDauraView,
        meta: {
            title: 'View Kath Daura Management', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/kath-daura/report',
        name: 'kath-daura-report',
        component: KathDauraReport,
        meta: {
            title: 'Report Kath Daura Management', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/kath-daura/:uid/template/:id',
        name: 'kath-daura-template',
        component: KathDauraTemplate,
        meta: {
            title: 'Kath Daura Template Management', rule: true,
            requiresAuth: true,
        }
    },


    //Cfug
    {
        path: '/cfug',
        name: 'cfug-index',
        component: CfugIndex,
        meta: {
            title: 'CFUG Management',
            requiresAuth: true,
        }
    },
    {
        path: '/cfug/create',
        name: 'cfug-create',
        component: CfugForm,
        meta: {
            title: 'Create CFUG Management',
            requiresAuth: true,
        }
    },
    {
        path: '/cfug/:uid/edit',
        name: 'cfug-edit',
        params: true,
        component: CfugForm,
        meta: {
            title: 'Edit CFUG Management',
            rule: true, requiresAuth: true,
        }
    },
    {
        path: '/cfug/:uid/view',
        name: 'cfug-view',
        component: CfugView,
        meta: {
            title: 'View CFUG Management', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/cfug/report',
        name: 'cfug-report',
        component: CfugReport,
        meta: {
            title: 'Report CFUG Management', rule: true,
            requiresAuth: true,
        }
    },


    //Jadibuti
    {
        path: '/jadibuti',
        name: 'jadibuti-index',
        component: JadibutiIndex,
        meta: {
            title: 'Jadibuti Management',
            requiresAuth: true,
        }
    },
    {
        path: '/jadibuti/create',
        name: 'jadibuti-create',
        component: JadibutiForm,
        meta: {
            title: 'Create Jadibuti Management',
            requiresAuth: true,
        }
    },
    {
        path: '/jadibuti/:uid/edit',
        name: 'jadibuti-edit',
        params: true,
        component: JadibutiForm,
        meta: {
            title: 'Edit Jadibuti Management',
            rule: true, requiresAuth: true,
        }
    },
    {
        path: '/jadibuti/:uid/view',
        name: 'jadibuti-view',
        component: JadibutiView,
        meta: {
            title: 'View Jadibuti Management', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/jadibuti/report',
        name: 'jadibuti-report',
        component: JadibutiReport,
        meta: {
            title: 'Report Jadibuti Management', rule: true,
            requiresAuth: true,
        }
    },

    //Jadibuti List
    {
        path: '/jadibuti-list',
        name: 'jadibuti-list-index',
        component: JadibutiListIndex,
        meta: {
            title: 'Jadibuti List Management',
            requiresAuth: true,
        }
    },
    {
        path: '/jadibuti-list/create',
        name: 'jadibuti-list-create',
        component: JadibutiListForm,
        meta: {
            title: 'Create Jadibuti List Management',
            requiresAuth: true,
        }
    },
    {
        path: '/jadibuti-list/:uid/edit',
        name: 'jadibuti-list-edit',
        params: true,
        component: JadibutiListForm,
        meta: {
            title: 'Edit Jadibuti List Management',
            rule: true, requiresAuth: true,
        }
    },
    {
        path: '/jadibuti/:uid/view',
        name: 'jadibuti-view',
        component: JadibutiView,
        meta: {
            title: 'View Jadibuti Management', rule: true,
            requiresAuth: true,
        }
    },
    //Leave
    {
        path: '/leave',
        name: 'leave-index',
        component: LeaveIndex,
        meta: {
            title: 'Leave Management',
            requiresAuth: true,
        }
    },
    {
        path: '/leave/create',
        name: 'leave-create',
        component: LeaveForm,
        meta: {
            title: 'Create Leave Management',
            requiresAuth: true,
        }
    },
    {
        path: '/leave/:uid/edit',
        name: 'leave-edit',
        params: true,
        component: LeaveForm,
        meta: {
            title: 'Edit Leave Management',
            rule: true, requiresAuth: true,
        }
    },
    {
        path: '/leave/:uid/view',
        name: 'leave-view',
        component: LeaveView,
        meta: {
            title: 'View Leave Management', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/leave-report',
        name: 'leave-report',
        component: LeaveView,
        meta: {
            title: 'Report Leave Management', rule: true,
            requiresAuth: true,
        }
    },

    //Niji Aabadi
    {
        path: '/niji-aabadi',
        name: 'niji-aabadi-index',
        component: NijiAabadiIndex,
        meta: {
            title: 'Niji Aabadi Management',
            requiresAuth: true,
        }
    },
    {
        path: '/niji-aabadi/create',
        name: 'niji-aabadi-create',
        component: NijiAabadiForm,
        meta: {
            title: 'Create Niji Aabadi Management',
            requiresAuth: true,
        }
    },
    {
        path: '/niji-aabadi/:uid/edit',
        name: 'niji-aabadi-edit',
        params: true,
        component: NijiAabadiForm,
        meta: {
            title: 'Edit Niji Aabadi Management',
            rule: true, requiresAuth: true,
        }
    },
    {
        path: '/niji-aabadi/:uid/view',
        name: 'niji-aabadi-view',
        component: NijiAabadiView,
        meta: {
            title: 'View Niji Aabadi Management', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/niji-aabadi/report',
        name: 'niji-aabadi-report',
        component: NijiAabadiReport,
        meta: {
            title: 'Report Niji Aabadi Management', rule: true,
            requiresAuth: true,
        }
    },

    //Feedback
    {
        path: '/feedbacks',
        name: 'feedback-index',
        component: FeedbackIndex,
        meta: {
            title: 'Feedback Management', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/feedbacks/circular',
        name: 'feedback-index',
        component: FeedbackIndex,
        meta: {
            title: 'Feedback Management', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/feedbacks/iee',
        name: 'feedback-index',
        component: FeedbackIndex,
        meta: {
            title: 'Feedback Management', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/feedbacks/heel',
        name: 'feedback-index',
        component: FeedbackIndex,
        meta: {
            title: 'Feedback Management', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/feedbacks/complaint',
        name: 'feedback-index',
        component: FeedbackIndex,
        meta: {
            title: 'Feedback Management', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/feedbacks/create',
        name: 'feedback-create',
        component: FeedbackForm,
        meta: {
            title: 'Create Feedback Management', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/feedbacks/:id/edit',
        name: 'feedback-edit',
        component: FeedbackForm,
        meta: {
            title: 'Edit Feedback Management', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/feedbacks/:id/show',
        name: 'feedback-view',
        component: FeedbackView,
        meta: {
            title: 'View Feedback Management', rule: true,
            requiresAuth: true,
        }
    },

    //CfDetail
    {
        path: '/cf-detail',
        name: 'cf-detail-index',
        component: CfDetailIndex,
        meta: {
            title: 'CF Management',
            requiresAuth: true,
        }
    },
    {
        path: '/cf-detail/create',
        name: 'cf-detail-create',
        component: CfDetailForm,
        meta: {
            title: 'Create CF Management',
            requiresAuth: true,
        }
    },
    {
        path: '/cf-detail/:uid/edit',
        name: 'cf-detail-edit',
        params: true,
        component: CfDetailForm,
        meta: {
            title: 'Edit CF Management',
            rule: true, requiresAuth: true,
        }
    },
    {
        path: '/cf-detail/:uid/view',
        name: 'cf-detail-view',
        component: CfDetailView,
        meta: {
            title: 'View CF Management', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/cf-detail/report',
        name: 'cf-detail-report',
        component: CfDetailReport,
        meta: {
            title: 'Report CF Management', rule: true,
            requiresAuth: true,
        }
    },

    //Lhf
    {
        path: '/lhf-detail',
        name: 'lhf-detail-index',
        component: LhfDetailIndex,
        meta: {
            title: 'LHF Detail Management',
            requiresAuth: true,
        }
    },
    {
        path: '/lhf-detail/create',
        name: 'lhf-detail-create',
        component: LhfDetailForm,
        meta: {
            title: 'Create LHF Detail Management',
            requiresAuth: true,
        }
    },
    {
        path: '/lhf-detail/:uid/edit',
        name: 'lhf-detail-edit',
        params: true,
        component: LhfDetailForm,
        meta: {
            title: 'Edit LHF Detail Management',
            rule: true, requiresAuth: true,
        }
    },
    {
        path: '/lhf-detail/:uid/view',
        name: 'lhf-detail-view',
        component: LhfDetailView,
        meta: {
            title: 'View LHF Detail Management', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/lhf-detail/report',
        name: 'lhf-detail-report',
        component: LhfDetailReport,
        meta: {
            title: 'Report LHF Detail Management', rule: true,
            requiresAuth: true,
        }
    },

    //Employee Detail
    {
        path: '/employee-detail',
        name: 'employee-detail-index',
        component: EmployeeDetailIndex,
        meta: {
            title: 'Employee Detail Management',
            requiresAuth: true,
        }
    },
    {
        path: '/employee-detail/create',
        name: 'employee-detail-create',
        component: EmployeeDetailForm,
        meta: {
            title: 'Create Employee Detail Management',
            requiresAuth: true,
        }
    },
    {
        path: '/employee-detail/:uid/edit',
        name: 'employee-detail-edit',
        params: true,
        component: EmployeeDetailForm,
        meta: {
            title: 'Edit Employee Detail Management',
            rule: true, requiresAuth: true,
        }
    },
    {
        path: '/employee-detail/:uid/view',
        name: 'employee-detail-view',
        component: EmployeeDetailView,
        meta: {
            title: 'View Employee Detail Management', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/employee-detail/report',
        name: 'employee-detail-report',
        component: EmployeeDetailReport,
        meta: {
            title: 'Report Employee Detail Management', rule: true,
            requiresAuth: true,
        }
    },

    //Case Detail
    {
        path: '/case-detail',
        name: 'case-detail-index',
        component: CaseDetailIndex,
        meta: {
            title: 'Case Detail Management',
            requiresAuth: true,
        }
    },
    {
        path: '/case-detail/create',
        name: 'case-detail-create',
        component: CaseDetailForm,
        meta: {
            title: 'Create Case Detail Management',
            requiresAuth: true,
        }
    },
    {
        path: '/case-detail/:uid/edit',
        name: 'case-detail-edit',
        params: true,
        component: CaseDetailForm,
        meta: {
            title: 'Edit Case Detail Management',
            rule: true, requiresAuth: true,
        }
    },
    {
        path: '/case-detail/:uid/view',
        name: 'case-detail-view',
        component: CaseDetailView,
        meta: {
            title: 'View Case Detail Management', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/case-detail/report',
        name: 'case-detail-report',
        component: CaseDetailReport,
        meta: {
            title: 'Report Case Detail Management', rule: true,
            requiresAuth: true,
        }
    },

    //Fifteen Detail
    {
        path: '/fifteen-detail',
        name: 'fifteen-detail-index',
        component: FifteenDetailIndex,
        meta: {
            title: 'Fifteen Detail Management',
            requiresAuth: true,
        }
    },
    {
        path: '/fifteen-detail/create',
        name: 'fifteen-detail-create',
        component: FifteenDetailForm,
        meta: {
            title: 'Create Fifteen Detail Management',
            requiresAuth: true,
        }
    },
    {
        path: '/fifteen-detail/:uid/edit',
        name: 'fifteen-detail-edit',
        params: true,
        component: FifteenDetailForm,
        meta: {
            title: 'Edit Fifteen Detail Management',
            rule: true, requiresAuth: true,
        }
    },
    {
        path: '/fifteen-detail/:uid/view',
        name: 'fifteen-detail-view',
        component: FifteenDetailView,
        meta: {
            title: 'View Fifteen Detail Management', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/fifteen-detail/report',
        name: 'fifteen-detail-report',
        component: FifteenDetailReport,
        meta: {
            title: 'Report Fifteen Detail Management', rule: true,
            requiresAuth: true,
        }
    },

    //Tippani
    {
        path: '/tippanis',
        name: 'tippani-index',
        component: TippaniIndex,
        params: true,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/tippanis/draft',
        name: 'tippani-draft',
        component: TippaniIndex,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/tippanis/own',
        name: 'tippani-own',
        component: TippaniIndex,
        params: true,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/tippanis/inbox',
        name: 'tippani-inbox',
        component: TippaniIndex,
        params: true,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/tippanis/forward',
        name: 'tippani-forward',
        component: TippaniIndex,
        params: true,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/tippanis/finalized',
        name: 'tippani-finalized',
        component: TippaniIndex,
        params: true,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/tippanis/create',
        name: 'tippani-create',
        component: TippaniForm,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/tippanis/:uid/edit',
        name: 'tippani-edit',
        params: true,
        component: TippaniForm,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/tippanis/:uid/view',
        name: 'tippani-view',
        params: true,
        component: TippaniView,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/tippanis/:uid/approved',
        name: 'tippani-approved',
        params: true,
        component: TippaniApproved,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/tippanis/:uid/print',
        name: 'print-post-view',
        params: true,
        component: PrintPostView,
        meta: {
            requiresAuth: true,
        }
    },

    //Template
    {
        path: '/templates',
        name: 'template-index',
        component: TemplateIndex,
        meta: {
            title: 'Template Management',
            requiresAuth: true,
        }
    },
    {
        path: '/templates/create',
        name: 'template-create',
        component: TemplateForm,
        meta: {
            title: 'Create Template Management',
            requiresAuth: true,
        }
    },
    {
        path: '/templates/:uid/edit',
        name: 'template-edit',
        params: true,
        component: TemplateForm,
        meta: {
            title: 'Edit Template Management',
            rule: true, requiresAuth: true,
        }
    },
    {
        path: '/templates/:uid/view',
        name: 'template-view',
        component: TemplateView,
        meta: {
            title: 'View Template Management', rule: true,
            requiresAuth: true,
        }
    },
    // {
    //     path: '/employee-detail/report',
    //     name: 'employee-detail-report',
    //     component: EmployeeDetailReport,
    //     meta: {
    //         title: 'Report Employee Detail Management', rule: true,
    //         requiresAuth: true,
    //     }
    // },

    //Common
    {
        path: '/common/template/view',
        name: 'common-template-view',
        component: CommonTemplateView,
        meta: {
            title: 'View Template Management', rule: true,
            requiresAuth: true,
        }
    },

    //Niji Jadibuti
    {
        path: '/niji-jadibuti',
        name: 'niji-jadibuti-index',
        component: NijiJadibutiIndex,
        meta: {
            title: 'Niji Jadibuti Management',
            requiresAuth: true,
        }
    },
    {
        path: '/niji-jadibuti/create',
        name: 'niji-jadibuti-create',
        component: NijiJadibutiForm,
        meta: {
            title: 'Create Niji Jadibuti Management',
            requiresAuth: true,
        }
    },
    {
        path: '/niji-jadibuti/:uid/edit',
        name: 'niji-jadibuti-edit',
        params: true,
        component: NijiJadibutiForm,
        meta: {
            title: 'Edit Niji Jadibuti Management',
            rule: true, requiresAuth: true,
        }
    },
    {
        path: '/niji-jadibuti/:uid/view',
        name: 'niji-jadibuti-view',
        component: NijiJadibutiView,
        meta: {
            title: 'View Niji Jadibuti Management', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/niji-jadibuti/report',
        name: 'niji-jadibuti-report',
        component: NijiJadibutiReport,
        meta: {
            title: 'Report Niji Jadibuti Management', rule: true,
            requiresAuth: true,
        }
    },

    //Niji Kath Daura Nikasi
    {
        path: '/niji-kath-daura-nikasi',
        name: 'niji-kath-daura-nikasi-index',
        component: NijiKathDauraNikasiIndex,
        meta: {
            title: 'Niji Kath Daura Nikasi Management',
            requiresAuth: true,
        }
    },
    {
        path: '/niji-kath-daura-nikasi/create',
        name: 'niji-kath-daura-nikasi-create',
        component: NijiKathDauraNikasiForm,
        meta: {
            title: 'Create Niji Kath Daura Nikasi  Management',
            requiresAuth: true,
        }
    },
    {
        path: '/niji-kath-daura-nikasi/:uid/edit',
        name: 'niji-kath-daura-nikasi-edit',
        params: true,
        component: NijiKathDauraNikasiForm,
        meta: {
            title: 'Edit Niji Kath Daura Nikasi Management',
            rule: true, requiresAuth: true,
        }
    },
    {
        path: '/niji-kath-daura-nikasi/:uid/view',
        name: 'niji-kath-daura-nikasi-view',
        component: NijiKathDauraNikasiView,
        meta: {
            title: 'View Niji Kath Daura Nikasi Management', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/niji-kath-daura-nikasi/report',
        name: 'niji-kath-daura-nikasi-report',
        component: NijiKathDauraNikasiReport,
        meta: {
            title: 'Report Niji Kath Daura Nikasi Management', rule: true,
            requiresAuth: true,
        }
    },

    //Niji Kath Daura Katani
    {
        path: '/niji-kath-daura-katani',
        name: 'niji-kath-daura-katani-index',
        component: NijiKathDauraKataniIndex,
        meta: {
            title: 'Niji Kath Daura Katani Management',
            requiresAuth: true,
        }
    },
    {
        path: '/niji-kath-daura-katani/create',
        name: 'niji-kath-daura-katani-create',
        component: NijiKathDauraKataniForm,
        meta: {
            title: 'Create Niji Kath Daura Katani  Management',
            requiresAuth: true,
        }
    },
    {
        path: '/niji-kath-daura-katani/:uid/edit',
        name: 'niji-kath-daura-katani-edit',
        params: true,
        component: NijiKathDauraKataniForm,
        meta: {
            title: 'Edit Niji Kath Daura Katani Management',
            rule: true, requiresAuth: true,
        }
    },
    {
        path: '/niji-kath-daura-katani/:uid/view',
        name: 'niji-kath-daura-katani-view',
        component: NijiKathDauraKataniView,
        meta: {
            title: 'View Niji Kath Daura Katani Management', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/niji-kath-daura-katani/report',
        name: 'niji-kath-daura-katani-report',
        component: NijiKathDauraKataniReport,
        meta: {
            title: 'Report Niji Kath Daura Katani Management', rule: true,
            requiresAuth: true,
        }
    },

    //Niji Jadabuti Nikasi
    {
        path: '/niji-jadibuti-nikasi',
        name: 'niji-jadibuti-nikasi-index',
        component: NijiJadibutiNikasiIndex,
        meta: {
            title: 'Niji Jadibuti Nikasi Management',
            requiresAuth: true,
        }
    },
    {
        path: '/niji-jadibuti-nikasi/create',
        name: 'niji-jadibuti-nikasi-create',
        component: NijiJadibutiNikasiForm,
        meta: {
            title: 'Create Niji Jadibuti Nikasi  Management',
            requiresAuth: true,
        }
    },
    {
        path: '/niji-jadibuti-nikasi/:uid/edit',
        name: 'niji-jadibuti-nikasi-edit',
        params: true,
        component: NijiJadibutiNikasiForm,
        meta: {
            title: 'Edit Niji Jadibuti Nikasi Management',
            rule: true, requiresAuth: true,
        }
    },
    {
        path: '/niji-jadibuti-nikasi/:uid/view',
        name: 'niji-jadibuti-nikasi-view',
        component: NijiJadibutiNikasiView,
        meta: {
            title: 'View Niji Jadibuti Nikasi Management', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/niji-jadibuti-nikasi/report',
        name: 'niji-jadibuti-nikasi-report',
        component: NijiJadibutiNikasiReport,
        meta: {
            title: 'Report Niji Jadibuti Nikasi Management', rule: true,
            requiresAuth: true,
        }
    },

    //Niji Jadabuti Sankalan
    {
        path: '/niji-jadibuti-sankalan',
        name: 'niji-jadibuti-sankalan-index',
        component: NijiJadibutiSankalanIndex,
        meta: {
            title: 'Niji Jadibuti Sankalan Management',
            requiresAuth: true,
        }
    },
    {
        path: '/niji-jadibuti-sankalan/create',
        name: 'niji-jadibuti-sankalan-create',
        component: NijiJadibutiSankalanForm,
        meta: {
            title: 'Create Niji Jadibuti Sankalan  Management',
            requiresAuth: true,
        }
    },
    {
        path: '/niji-jadibuti-sankalan/:uid/edit',
        name: 'niji-jadibuti-sankalan-edit',
        params: true,
        component: NijiJadibutiSankalanForm,
        meta: {
            title: 'Edit Niji Jadibuti Sankalan Management',
            rule: true, requiresAuth: true,
        }
    },
    {
        path: '/niji-jadibuti-sankalan/:uid/view',
        name: 'niji-jadibuti-sankalan-view',
        component: NijiJadibutiSankalanView,
        meta: {
            title: 'View Niji Jadibuti Sankalan Management', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/niji-jadibuti-sankalan/report',
        name: 'niji-jadibuti-sankalan-report',
        component: NijiJadibutiSankalanReport,
        meta: {
            title: 'Report Niji Jadibuti Sankalan Management', rule: true,
            requiresAuth: true,
        }
    },


    //Samudayik Jadabuti Nikasi
    {
        path: '/samudayik-jadibuti-nikasi',
        name: 'samudayik-jadibuti-nikasi-index',
        component: SamudayikJadibutiNikasiIndex,
        meta: {
            title: 'Samudayik Jadibuti Nikasi Management',
            requiresAuth: true,
        }
    },
    {
        path: '/samudayik-jadibuti-nikasi/create',
        name: 'samudayik-jadibuti-nikasi-create',
        component: SamudayikJadibutiNikasiForm,
        meta: {
            title: 'Create Samudayik Jadibuti Nikasi  Management',
            requiresAuth: true,
        }
    },
    {
        path: '/samudayik-jadibuti-nikasi/:uid/edit',
        name: 'samudayik-jadibuti-nikasi-edit',
        params: true,
        component: SamudayikJadibutiNikasiForm,
        meta: {
            title: 'Edit Samudayik Jadibuti Nikasi Management',
            rule: true, requiresAuth: true,
        }
    },
    {
        path: '/samudayik-jadibuti-nikasi/:uid/view',
        name: 'samudayik-jadibuti-nikasi-view',
        component: SamudayikJadibutiNikasiView,
        meta: {
            title: 'View Samudayik Jadibuti Nikasi Management', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/samudayik-jadibuti-nikasi/report',
        name: 'samudayik-jadibuti-nikasi-report',
        component: SamudayikJadibutiNikasiReport,
        meta: {
            title: 'Report Samudayik Jadibuti Nikasi Management', rule: true,
            requiresAuth: true,
        }
    },

    //Samudayik Jadabuti Sankalan
    {
        path: '/samudayik-jadibuti-sankalan',
        name: 'samudayik-jadibuti-sankalan-index',
        component: SamudayikJadibutiSankalanIndex,
        meta: {
            title: 'Samudayik Jadibuti Sankalan Management',
            requiresAuth: true,
        }
    },
    {
        path: '/samudayik-jadibuti-sankalan/create',
        name: 'samudayik-jadibuti-sankalan-create',
        component: SamudayikJadibutiSankalanForm,
        meta: {
            title: 'Create Samudayik Jadibuti Sankalan  Management',
            requiresAuth: true,
        }
    },
    {
        path: '/samudayik-jadibuti-sankalan/:uid/edit',
        name: 'samudayik-jadibuti-sankalan-edit',
        params: true,
        component: SamudayikJadibutiSankalanForm,
        meta: {
            title: 'Edit Samudayik Jadibuti Sankalan Management',
            rule: true, requiresAuth: true,
        }
    },
    {
        path: '/samudayik-jadibuti-sankalan/:uid/view',
        name: 'samudayik-jadibuti-sankalan-view',
        component: SamudayikJadibutiSankalanView,
        meta: {
            title: 'View Samudayik Jadibuti Sankalan Management', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/samudayik-jadibuti-sankalan/report',
        name: 'samudayik-jadibuti-sankalan-report',
        component: SamudayikJadibutiSankalanReport,
        meta: {
            title: 'Report Samudayik Jadibuti Sankalan Management', rule: true,
            requiresAuth: true,
        }
    },


    //Samudayik KathDaura Nikasi
    {
        path: '/samudayik-kath-daura-nikasi',
        name: 'samudayik-kath-daura-nikasi-index',
        component: SamudayikKathDauraNikasiIndex,
        meta: {
            title: 'Samudayik KathDaura Nikasi Management',
            requiresAuth: true,
        }
    },
    {
        path: '/samudayik-kath-daura-nikasi/create',
        name: 'samudayik-kath-daura-nikasi-create',
        component: SamudayikKathDauraNikasiForm,
        meta: {
            title: 'Create Samudayik KathDaura Nikasi  Management',
            requiresAuth: true,
        }
    },
    {
        path: '/samudayik-kath-daura-nikasi/:uid/edit',
        name: 'samudayik-kath-daura-nikasi-edit',
        params: true,
        component: SamudayikKathDauraNikasiForm,
        meta: {
            title: 'Edit Samudayik KathDaura Nikasi Management',
            rule: true, requiresAuth: true,
        }
    },
    {
        path: '/samudayik-kath-daura-nikasi/:uid/view',
        name: 'samudayik-kath-daura-nikasi-view',
        component: SamudayikKathDauraNikasiView,
        meta: {
            title: 'View Samudayik KathDaura Nikasi Management', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/samudayik-kath-daura-nikasi/report',
        name: 'samudayik-kath-daura-nikasi-report',
        component: SamudayikKathDauraNikasiReport,
        meta: {
            title: 'Report Samudayik KathDaura Nikasi Management', rule: true,
            requiresAuth: true,
        }
    },

    //Samudayik KathDaura Sankalan
    {
        path: '/samudayik-kath-daura-sankalan',
        name: 'samudayik-kath-daura-sankalan-index',
        component: SamudayikKathDauraSankalanIndex,
        meta: {
            title: 'Samudayik KathDaura Sankalan Management',
            requiresAuth: true,
        }
    },
    {
        path: '/samudayik-kath-daura-sankalan/create',
        name: 'samudayik-kath-daura-sankalan-create',
        component: SamudayikKathDauraSankalanForm,
        meta: {
            title: 'Create Samudayik KathDaura Sankalan  Management',
            requiresAuth: true,
        }
    },
    {
        path: '/samudayik-kath-daura-sankalan/:uid/edit',
        name: 'samudayik-kath-daura-sankalan-edit',
        params: true,
        component: SamudayikKathDauraSankalanForm,
        meta: {
            title: 'Edit Samudayik KathDaura Sankalan Management',
            rule: true, requiresAuth: true,
        }
    },
    {
        path: '/samudayik-kath-daura-sankalan/:uid/view',
        name: 'samudayik-kath-daura-sankalan-view',
        component: SamudayikKathDauraSankalanView,
        meta: {
            title: 'View Samudayik KathDaura Sankalan Management', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/samudayik-kath-daura-sankalan/report',
        name: 'samudayik-kath-daura-sankalan-report',
        component: SamudayikKathDauraSankalanReport,
        meta: {
            title: 'Report Samudayik KathDaura Sankalan Management', rule: true,
            requiresAuth: true,
        }
    },

    //Samudayik Khoto Nikasi
    {
        path: '/samudayik-khoto-nikasi',
        name: 'samudayik-khoto-nikasi-index',
        component: SamudayikKhotoNikasiIndex,
        meta: {
            title: 'Samudayik Khoto Nikasi Management',
            requiresAuth: true,
        }
    },
    {
        path: '/samudayik-khoto-nikasi/create',
        name: 'samudayik-khoto-nikasi-create',
        component: SamudayikKhotoNikasiForm,
        meta: {
            title: 'Create Samudayik Khoto Nikasi  Management',
            requiresAuth: true,
        }
    },
    {
        path: '/samudayik-khoto-nikasi/:uid/edit',
        name: 'samudayik-khoto-nikasi-edit',
        params: true,
        component: SamudayikKhotoNikasiForm,
        meta: {
            title: 'Edit Samudayik Khoto Nikasi Management',
            rule: true, requiresAuth: true,
        }
    },
    {
        path: '/samudayik-khoto-nikasi/:uid/view',
        name: 'samudayik-khoto-nikasi-view',
        component: SamudayikKhotoNikasiView,
        meta: {
            title: 'View Samudayik Khoto Nikasi Management', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/samudayik-khoto-nikasi/report',
        name: 'samudayik-khoto-nikasi-report',
        component: SamudayikKhotoNikasiReport,
        meta: {
            title: 'Report Samudayik Khoto Nikasi Management', rule: true,
            requiresAuth: true,
        }
    },
    //Samudayik Khoto Sankalan
    {
        path: '/samudayik-khoto-sankalan',
        name: 'samudayik-khoto-sankalan-index',
        component: SamudayikKhotoSankalanIndex,
        meta: {
            title: 'Samudayik Khoto Sankalan Management',
            requiresAuth: true,
        }
    },
    {
        path: '/samudayik-khoto-sankalan/create',
        name: 'samudayik-khoto-sankalan-create',
        component: SamudayikKhotoSankalanForm,
        meta: {
            title: 'Create Samudayik Khoto Sankalan  Management',
            requiresAuth: true,
        }
    },
    {
        path: '/samudayik-khoto-sankalan/:uid/edit',
        name: 'samudayik-khoto-sankalan-edit',
        params: true,
        component: SamudayikKhotoSankalanForm,
        meta: {
            title: 'Edit Samudayik Khoto Sankalan Management',
            rule: true, requiresAuth: true,
        }
    },
    {
        path: '/samudayik-khoto-sankalan/:uid/view',
        name: 'samudayik-khoto-sankalan-view',
        component: SamudayikKhotoSankalanView,
        meta: {
            title: 'View Samudayik Khoto Sankalan Management', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/samudayik-khoto-sankalan/report',
        name: 'samudayik-khoto-sankalan-report',
        component: SamudayikKhotoSankalanReport,
        meta: {
            title: 'Report Samudayik Khoto Sankalan Management', rule: true,
            requiresAuth: true,
        }
    },


    //Aadabi Chhodpurji
    {
        path: '/aabadi-chhodpurji',
        name: 'aabadi-chhodpurji-index',
        component: AabadiChhodpurjiIndex,
        meta: {
            title: 'Aabadi Chhodpurji Management',
            requiresAuth: true,
        }
    },
    {
        path: '/aabadi-chhodpurji/create',
        name: 'aabadi-chhodpurji-create',
        component: AabadiChhodpurjiForm,
        meta: {
            title: 'Create Aabadi Chhodpurji  Management',
            requiresAuth: true,
        }
    },
    {
        path: '/aabadi-chhodpurji/:uid/edit',
        name: 'aabadi-chhodpurji-edit',
        params: true,
        component: AabadiChhodpurjiForm,
        meta: {
            title: 'Edit Aabadi Chhodpurji Management',
            rule: true, requiresAuth: true,
        }
    },
    {
        path: '/aabadi-chhodpurji/:uid/view',
        name: 'aabadi-chhodpurji-view',
        component: AabadiChhodpurjiView,
        meta: {
            title: 'View Aabadi Chhodpurji Management', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/aabadi-chhodpurji/report',
        name: 'aabadi-chhodpurji-report',
        component: AabadiChhodpurjiReport,
        meta: {
            title: 'Report Aabadi Chhodpurji Management', rule: true,
            requiresAuth: true,
        }
    },

    //Aabadi Katan
    {
        path: '/aabadi-katan',
        name: 'aabadi-katan-index',
        component: AabadiKatanIndex,
        meta: {
            title: 'Aabadi Katan Management',
            requiresAuth: true,
        }
    },
    {
        path: '/aabadi-katan/create',
        name: 'aabadi-katan-create',
        component: AabadiKatanForm,
        meta: {
            title: 'Create Aabadi Katan  Management',
            requiresAuth: true,
        }
    },
    {
        path: '/aabadi-katan/:uid/edit',
        name: 'aabadi-katan-edit',
        params: true,
        component: AabadiKatanForm,
        meta: {
            title: 'Edit Aabadi Katan Management',
            rule: true, requiresAuth: true,
        }
    },
    {
        path: '/aabadi-katan/:uid/view',
        name: 'aabadi-katan-view',
        component: AabadiKatanView,
        meta: {
            title: 'View Aabadi Katan Management', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/aabadi-katan/report',
        name: 'aabadi-katan-report',
        component: AabadiKatanReport,
        meta: {
            title: 'Report Aabadi Katan Management', rule: true,
            requiresAuth: true,
        }
    },

    //Sarkari Jadabuti Nikasi
    {
        path: '/sarkari-jadibuti-chhodpurji',
        name: 'sarkari-jadibuti-chhodpurji-index',
        component: SarkariJadibutiChhodpurjiIndex,
        meta: {
            title: 'Sarkari Jadibuti Chhodpurji Management',
            requiresAuth: true,
        }
    },
    {
        path: '/sarkari-jadibuti-chhodpurji/create',
        name: 'sarkari-jadibuti-chhodpurji-create',
        component: SarkariJadibutiChhodpurjiForm,
        meta: {
            title: 'Create Sarkari Jadibuti Chhodpurji  Management',
            requiresAuth: true,
        }
    },
    {
        path: '/sarkari-jadibuti-chhodpurji/:uid/edit',
        name: 'sarkari-jadibuti-chhodpurji-edit',
        params: true,
        component: SarkariJadibutiChhodpurjiForm,
        meta: {
            title: 'Edit Sarkari Jadibuti Chhodpurji Management',
            rule: true, requiresAuth: true,
        }
    },
    {
        path: '/sarkari-jadibuti-chhodpurji/:uid/view',
        name: 'sarkari-jadibuti-chhodpurji-view',
        component: SarkariJadibutiChhodpurjiView,
        meta: {
            title: 'View Sarkari Jadibuti Chhodpurji Management', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/sarkari-jadibuti-chhodpurji/report',
        name: 'sarkari-jadibuti-chhodpurji-report',
        component: SarkariJadibutiChhodpurjiReport,
        meta: {
            title: 'Report Sarkari Jadibuti Chhodpurji Management', rule: true,
            requiresAuth: true,
        }
    },

    //Sarkari Jadabuti Sankalan
    {
        path: '/sarkari-jadibuti-sankalan',
        name: 'sarkari-jadibuti-sankalan-index',
        component: SarkariJadibutiSankalanIndex,
        meta: {
            title: 'Sarkari Jadibuti Sankalan Management',
            requiresAuth: true,
        }
    },
    {
        path: '/sarkari-jadibuti-sankalan/create',
        name: 'sarkari-jadibuti-sankalan-create',
        component: SarkariJadibutiSankalanForm,
        meta: {
            title: 'Create Sarkari Jadibuti Sankalan  Management',
            requiresAuth: true,
        }
    },
    {
        path: '/sarkari-jadibuti-sankalan/:uid/edit',
        name: 'sarkari-jadibuti-sankalan-edit',
        params: true,
        component: SarkariJadibutiSankalanForm,
        meta: {
            title: 'Edit Sarkari Jadibuti Sankalan Management',
            rule: true, requiresAuth: true,
        }
    },
    {
        path: '/sarkari-jadibuti-sankalan/:uid/view',
        name: 'sarkari-jadibuti-sankalan-view',
        component: SarkariJadibutiSankalanView,
        meta: {
            title: 'View Sarkari Jadibuti Sankalan Management', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/sarkari-jadibuti-sankalan/report',
        name: 'sarkari-jadibuti-sankalan-report',
        component: SarkariJadibutiSankalanReport,
        meta: {
            title: 'Report Sarkari Jadibuti Sankalan Management', rule: true,
            requiresAuth: true,
        }
    },


    //Sarkari KathDaura Chhodpurji
    {
        path: '/sarkari-kath-daura-chhodpurji',
        name: 'sarkari-kath-daura-chhodpurji-index',
        component: SarkariKathDauraChhodpurjiIndex,
        meta: {
            title: 'Sarkari KathDaura Chhodpurji Management',
            requiresAuth: true,
        }
    },
    {
        path: '/sarkari-kath-daura-chhodpurji/create',
        name: 'sarkari-kath-daura-chhodpurji-create',
        component: SarkariKathDauraChhodpurjiForm,
        meta: {
            title: 'Create Sarkari KathDaura Chhodpurji  Management',
            requiresAuth: true,
        }
    },
    {
        path: '/sarkari-kath-daura-chhodpurji/:uid/edit',
        name: 'sarkari-kath-daura-chhodpurji-edit',
        params: true,
        component: SarkariKathDauraChhodpurjiForm,
        meta: {
            title: 'Edit Sarkari KathDaura Chhodpurji Management',
            rule: true, requiresAuth: true,
        }
    },
    {
        path: '/sarkari-kath-daura-chhodpurji/:uid/view',
        name: 'sarkari-kath-daura-chhodpurji-view',
        component: SarkariKathDauraChhodpurjiView,
        meta: {
            title: 'View Sarkari KathDaura Chhodpurji Management', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/sarkari-kath-daura-chhodpurji/report',
        name: 'sarkari-kath-daura-chhodpurji-report',
        component: SarkariKathDauraChhodpurjiReport,
        meta: {
            title: 'Report Sarkari KathDaura Chhodpurji Management', rule: true,
            requiresAuth: true,
        }
    },

    //Sarkari KathDaura Katan
    {
        path: '/sarkari-kath-daura-katan',
        name: 'sarkari-kath-daura-katan-index',
        component: SarkariKathDauraKatanIndex,
        meta: {
            title: 'Sarkari KathDaura Katan Management',
            requiresAuth: true,
        }
    },
    {
        path: '/sarkari-kath-daura-katan/create',
        name: 'sarkari-kath-daura-katan-create',
        component: SarkariKathDauraKatanForm,
        meta: {
            title: 'Create Sarkari KathDaura Katan  Management',
            requiresAuth: true,
        }
    },
    {
        path: '/sarkari-kath-daura-katan/:uid/edit',
        name: 'sarkari-kath-daura-katan-edit',
        params: true,
        component: SarkariKathDauraKatanForm,
        meta: {
            title: 'Edit Sarkari KathDaura Katan Management',
            rule: true, requiresAuth: true,
        }
    },
    {
        path: '/sarkari-kath-daura-katan/:uid/view',
        name: 'sarkari-kath-daura-katan-view',
        component: SarkariKathDauraKatanView,
        meta: {
            title: 'View Sarkari KathDaura Katan Management', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/sarkari-kath-daura-katan/report',
        name: 'sarkari-kath-daura-katan-report',
        component: SarkariKathDauraKatanReport,
        meta: {
            title: 'Report Sarkari KathDaura Katan Management', rule: true,
            requiresAuth: true,
        }
    },

    //Lilam Sahamati
    {
        path: '/lilam-sahamati',
        name: 'lilam-sahamati-index',
        component: LilamSahamatiIndex,
        meta: {
            title: 'Sarkari Lilam Sahamati Management',
            requiresAuth: true,
        }
    },
    {
        path: '/lilam-sahamati/create',
        name: 'lilam-sahamati-create',
        component: LilamSahamatiForm,
        meta: {
            title: 'Create Lilam Sahamati Management',
            requiresAuth: true,
        }
    },
    {
        path: '/lilam-sahamati/:uid/edit',
        name: 'lilam-sahamati-edit',
        params: true,
        component: LilamSahamatiForm,
        meta: {
            title: 'Edit Lilam Sahamati Management',
            rule: true, requiresAuth: true,
        }
    },
    {
        path: '/lilam-sahamati/:uid/view',
        name: 'lilam-sahamati-view',
        component: LilamSahamatiView,
        meta: {
            title: 'View Lilam Sahamati Management', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/lilam-sahamati/report',
        name: 'lilam-sahamati-report',
        component: LilamSahamatiReport,
        meta: {
            title: 'Report Lilam Sahamati Management', rule: true,
            requiresAuth: true,
        }
    },

    //Sthanantaran Sahamati
    {
        path: '/sthanantaran-sahamati',
        name: 'sthanantaran-sahamati-index',
        component: SthanantaranSahamatiIndex,
        meta: {
            title: 'Sarkari Sthanantaran Sahamati Management',
            requiresAuth: true,
        }
    },
    {
        path: '/sthanantaran-sahamati/create',
        name: 'sthanantaran-sahamati-create',
        component: SthanantaranSahamatiForm,
        meta: {
            title: 'Create Sthanantaran Sahamati Management',
            requiresAuth: true,
        }
    },
    {
        path: '/sthanantaran-sahamati/:uid/edit',
        name: 'sthanantaran-sahamati-edit',
        params: true,
        component: SthanantaranSahamatiForm,
        meta: {
            title: 'Edit Sthanantaran Sahamati Management',
            rule: true, requiresAuth: true,
        }
    },
    {
        path: '/sthanantaran-sahamati/:uid/view',
        name: 'sthanantaran-sahamati-view',
        component: SthanantaranSahamatiView,
        meta: {
            title: 'View Sthanantaran Sahamati Management', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/sthanantaran-sahamati/report',
        name: 'sthanantaran-sahamati-report',
        component: SthanantaranSahamatiReport,
        meta: {
            title: 'Report Sthanantaran Sahamati Management', rule: true,
            requiresAuth: true,
        }
    },

    //Lilam Bikri
    {
        path: '/lilam-bikri',
        name: 'lilam-bikri-index',
        component: LilamBikriIndex,
        meta: {
            title: 'Lilam Bikri Management',
            requiresAuth: true,
        }
    },
    {
        path: '/lilam-bikri/create',
        name: 'lilam-bikri-create',
        component: LilamBikriForm,
        meta: {
            title: 'Create Lilam Bikri Management',
            requiresAuth: true,
        }
    },
    {
        path: '/lilam-bikri/:uid/edit',
        name: 'lilam-bikri-edit',
        params: true,
        component: LilamBikriForm,
        meta: {
            title: 'Edit Lilam Bikri Management',
            rule: true, requiresAuth: true,
        }
    },
    {
        path: '/lilam-bikri/:uid/view',
        name: 'lilam-bikri-view',
        component: LilamBikriView,
        meta: {
            title: 'View Lilam Bikri Management', rule: true,
            requiresAuth: true,
        }
    },

    {
        path: '/lilam-bikri/report',
        name: 'lilam-bikri-report',
        component: LilamBikriReport,
        meta: {
            title: 'Report Lilam Bikri Management', rule: true,
            requiresAuth: true,
        }
    },
];

export default routes
